import styled from "styled-components";
import generalData from "../../assets/data/general";
import { colors, fonts } from "../../constants/constants";

const Flex = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 2rem;

  @media (min-width: 48rem) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
  }
  > div {
    h3 {
      font-family: ${fonts.primary};
      font-weight: 600;
      color: #000;
    }
  }
`;

const Technologies = styled.ul`
  padding-left: 0;

  list-style-type: none;

  li {
    position: relative;

    margin-bottom: 0.5rem;
    padding-left: 20px;

    color: ${colors.grey};

    &:before {
      position: absolute;
      content: "";
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      background-color: ${colors.primary};
      animation: 7s ease-in-out infinite alternate borderSquareCircle;
    }
  }
`;

function TechnologiesList() {
  return (
    <Flex>
      <div>
        <h3>Technologies I've worked with</h3>
        <Technologies>
          {generalData.technologies.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Technologies>
      </div>
      <div>
        <h3>Technologies I'd love to work with (improve)</h3>
        <Technologies>
          {generalData.technologiesImprove.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Technologies>
      </div>
    </Flex>
  );
}

export default TechnologiesList;
