import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import styled from "styled-components";
import { colors } from "../../constants/constants";
import useEscape from "../../hooks/useEscape";
import useHideOverflow from "../../hooks/useHideOverflow";
import { Project } from "../../types/project";
import ProjectItem from "../project-item/project-item";

const ProjectContainer = styled.div.attrs((props: { page: number }) => props)`
  // display: flex;
  // flex-direction: row;
  // parent-container rond 2 projeten .. carousel
  overflow: hidden;
  width: 100%;
  heigth: 100%;

  > div {
    // 2 projecten .. inner carousel
    // transition: transform 0.6s ease-in-out;
    // transform: translateX(${(props) => (props.page - 1) * -100}%);
  }
`;
const ProjectInnerContainer = styled(motion.div)`
  // border: solid 2Px red;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const List = styled(motion.div)`
  flex-shrink: 0;
  width: 100%;

  display: flex;
  flex-direction: column;

  > div {
    &:last-child {
      margin-bottom: 2rem;
    }
  }
`;

const PageNavigation = styled.div`
  margin: 0 auto;
  ul {
    list-style-type: none;
    padding-left: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    li {
      margin-right: 1rem;
      cursor: pointer;

      a {
        width: 100%;
        height: 100%;
      }

      &:first-child {
        a {
          display: flex;
          justify-content: center;
          align-items: center;

          font-size: 1.5rem;
        }
        background-color: transparent;
        color: ${colors.primary};
      }

      &:last-child {
        margin-right: 0;
        a {
          display: flex;
          justify-content: center;
          align-items: center;

          font-size: 1.5rem;
        }
        background-color: transparent;
        color: ${colors.primary};
      }
    }
  }
`;

const Circle = styled.li.attrs((props: { active: boolean }) => props)`
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 1rem;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? colors.primary : colors.primaryLight};
`;

interface ProjectListProps {
  projects: Project[];
  menuOpen: boolean;
}

function ProjectList({ projects, menuOpen }: ProjectListProps) {
  const [pagination, setPagination] = useState(1);
  const pages = Math.ceil(projects.length / 3);
  const [resetPreviews, setResetPreviews] = useState(false);
  const [fullScreenProject, setFullScreenProject] = useState(-1);

  useEscape(() => setFullScreenProject(-1));

  useEffect(() => {
    // if (fullScreenProject !== -1) {
    //   useHideOverflow(true);
    // } else {
    //   useHideOverflow(false);
    // }

    if(menuOpen) {
      setFullScreenProject(-1);
    }
  }, [menuOpen])

  let projectsGrouped: Project[][] = [];
  for (let i = 0; i < pages; i++) {
    projectsGrouped.push(projects.slice(i * 3, i * 3 + 3));
  }

  const handlePagination = (direction: string) => {
    if (direction === "next") {
      if (pagination < pages) {
        setPagination(pagination + 1);
      } else {
        setPagination(1);
      }
    }
    if (direction === "prev") {
      if (pagination > 1) {
        setPagination(pagination - 1);
      } else {
        setPagination(pages);
      }
    }

    // set timer to reset previews on next render
    resetPrevies();
  };

  const resetPrevies = () => {
    setResetPreviews(true);
    setTimeout(() => {
      setResetPreviews(false);
    }, 1000);
  };

  const enableFullscreen = (projectIndex: number) => {
    if(fullScreenProject !== -1) {
      setFullScreenProject(-1);
    } else {
      setFullScreenProject(projectIndex);
    }
  }

  return (
    <>
      <ProjectContainer page={pagination}>
        <ProjectInnerContainer>
          <List>
            {projectsGrouped[pagination - 1].map((project, j) => (
              <ProjectItem key={j} {...project} reset={resetPreviews} enableFullScreen={() => enableFullscreen(j)} fullscreen={ fullScreenProject !== -1 && fullScreenProject === j }/>
            ))}
          </List>
        </ProjectInnerContainer>
      </ProjectContainer>

      <PageNavigation>
        <ul>
          <li>
            <a onClick={() => handlePagination("prev")}>
              <FiChevronLeft />
            </a>
          </li>
          {Array.from(Array(pages).keys()).map((page) => (
            <Circle
              active={page + 1 === pagination ? true : false}
              onClick={() => {
                setPagination(page + 1);
                resetPrevies();
              }}
            ></Circle>
          ))}
          <li>
            <a onClick={() => handlePagination("next")}>
              <FiChevronRight />
            </a>
          </li>
        </ul>
      </PageNavigation>
    </>
  );
}

export default ProjectList;
