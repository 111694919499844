import { FiGithub, FiLinkedin, FiMail, FiPhone } from "react-icons/fi";
import { SiDiscord } from "react-icons/si";
import styled from "styled-components";
import { colors } from "../../constants/constants";

const Container = styled.div`
  padding: 0.5rem 0;

  background-color: ${colors.primaryLight};
  box-shadow: 0 -10px 15px -3px rgb(0 0 0 / 0.1),
    0 -4px 6px -4px rgb(0 0 0 / 0.1);
`;

const Grid = styled.div`
  max-width: 70rem;

  margin: 0 auto;
  padding: 1rem 2rem;

  display: flex;
  flex-direction: column;
  
  gap: 1rem;
  

  a:hover {
    transition: all 0.3s ease-in-out;
    color: #fff !important;
  }

  &:nth-child(1) {
    & a {
      grid-area: cv;
      text-decoration: none;
      color: #000;
    }
  }
`;

const Top = styled.div`
display: flex;
justify-content: space-between;
`;
const Bottom = styled.div`
display: flex;
flex-direction: column;

// border: solid 2Px red;


`;

const Text = styled.p`
  grid-area: author;

  order: -1;

  margin: 0;
  text-align: center;

  @media (min-width: 48rem) {
    order: 0;
    }
`;

const Socials = styled.ul`
  grid-area: socials;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  margin-bottom: 0;

  display: flex;
  flex-direction: row;
  margin-left: auto;

  li {
    margin-left: 1rem;
  }
`;

const Socials2 = styled.div`

  grid-area: socials2;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  @media (min-width: 48rem) {
    flex-direction: row;
    justify-content: space-between;
  }

  > div {
    a {
      display: flex;
      align-items: flex-end;
    }

    &:nth-child(1) {
      span {
        margin-left: 1rem;
      }
    }

    &:nth-child(3) {
      // margin-top: 1rem;

      @media (min-width: 48rem) {
        margin-top: 0;
      }

      a {
        flex-direction: row-reverse;
        @media (min-width: 48rem) {
          flex-direction: row;
        }
      }

      span {
        margin-left: 1rem;

        @media (min-width: 48rem) {
          margin-left: 0rem;
          margin-right: 1rem;
        }
      }
    }
  }
`;

function Footer() {
  return (
    <Container>
      <Grid>
        <Top>
        <div>
          <a href="https://cv.jeroendewelde.be" target="_blank">CV</a>
        </div>
        <Socials>
        <li>
            <a href="https://discord.com/users/JeroenD#9747" target="_blank">
              <SiDiscord />
            </a>
          </li>
          <li>
            <a href="https://github.com/pgm-jeroendewelde" target="_blank">
              <FiGithub />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/jeroen-dewelde-162533134/" target="_blank">
              <FiLinkedin />
            </a>
          </li>
        </Socials>
        </Top>
        <Bottom>

        
        
        

        

        <Socials2>
          <div>
            <a href="mailto:Jeroen.Dewelde@gmail.com">
              <FiMail />
              <span>Jeroen.Dewelde@gmail.com</span>
            </a>
          </div>
          <Text>Made by Jeroen Dewelde</Text>
          <div>
            <a href="tel:+32476267924">
              <span>+32 476 26 79 24</span>
              <FiPhone />
            </a>
          </div>
        </Socials2>
        </Bottom>
      </Grid>
    </Container>
  );
}

export default Footer;
