import { FiGithub, FiLinkedin, FiMail, FiPhone } from "react-icons/fi";
import styled from "styled-components";
import { colors } from "../../constants/constants";

const linksData = [
  {
    title: "Home",
    href: "#",
  },
  {
    title: "Projects",
    href: "#projects",
  },
  {
    title: "Professional Growth",
    href: "#professional-growth",
  },
  {
    title: "Technologies",
    href: "#technologies",
  },
];

const socialsData = [
  {
    title: "Github",
    href: "https://github.com/pgm-jeroendewelde",
    icon: <FiGithub />,
    target: "_blank",
  },
  {
    title: "LinkedIn",
    href: "https://www.linkedin.com/in/jeroen-dewelde-162533134/",
    icon: <FiLinkedin />,
    target: "_blank",
  },
  {
    title: "Phone",
    href: "tel:+32476267924",
    icon: <FiPhone />,
  },
  {
    title: "Mail",
    href: "mailto:Jeroen.Dewelde@gmail.com",
    icon: <FiMail />,
  },
];

const Container = styled.div.attrs((props: { active: boolean }) => props)`
  width: 100%;
  height: calc(100vh - 3rem);

  position: fixed;
  z-index: 300;
  top: 3rem;
  left: 0;

  visibility: hidden;
  opacity: 0;

  box-shadow: -10px 10px 15px -3px rgb(0 0 0 / 0.1),
    -10px 4px 6px -4px rgb(0 0 0 / 0.1);

  transform: translateX(100%);
  transition: all 0.4s ease-in-out;

  background-color: ${colors.primary};
  border-radius: 4px;

  color: #fff;

  padding: 0 2rem 0 1rem;

  @media (min-width: 48rem) {
    max-width: 30rem;
    right: 0;
    left: auto;
    height: auto;
  }

  @media (min-width: 70rem) {
    right: calc(50vw - 35rem);
  }

  ${(props) =>
    props.active &&
    `
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    `}
`;

const SectionsList = styled.ul`
  list-style-type: none;
  padding-left: 0;

  display: flex;
  flex-direction: column;

  > li {
    position: relative;

    padding: 2rem 0 2rem 0;
    font-weight: 400;

    a,
    a:visited {
      position: relative;

      padding-left: 1rem;

      text-decoration: none;

      color: #fff;

      &:before {
        content: "";
        position: absolute;
        width: 4px;
        height: 4px;

        top: 50%;
        left: 0;

        transform: translateY(-50%);

        border-radius: 50%;
        background-color: #fff;

        transition: all 0.3s ease-in-out;
      }

      &:hover,
      &:active,
      &:focus {
        color: #fff;

        &::after {
          opacity: 1;
          width: 100%;
        }

        &:before {
          width: calc(100% - 1rem);
          height: 2px;
          border-radius: 2px;
          left: 1rem;
          top: 100%;
          transform: translateY(2px);
        }
      }
    }
  }
`;
const SocialsList = styled.ul`
    list-style-type: none;

    display: flex;
    justify-content: flex-start;

    padding: 0;
    padding-left: 0;
    
    li {
      margin-left: 0.5rem;
      margin-right: 1rem;

      a, a:visited {
          width: 2.5rem;
          height: 2.5rem;

          display: flex;
          align-items: center;
          justify-content: center;

        padding-left: 0;

        border: solid 2px ${colors.primary};
        border-radius: 50%;
        color: #FFF;
        
        transition: border-color 0s linear 0.5s;
        
        &:hover, &:active, &:focus {
          animation-duration: 0.5s;
          animation-name: animeCircleBorderColor;
          animation-iteration-count: 1;

          transition-delay: 0s;
          
          border-color: #fff;
        }
      }
    
    `;

interface MobileNavProps {
  active: boolean;
  hideMenu: () => void;
}

function MobileNav({ active, hideMenu }: MobileNavProps) {
  return (
    <>
      <Container active={active}>
        <SectionsList>
          {linksData.map((link, index) => (
            <li key={index}>
              <a href={link.href} onClick={hideMenu}>
                {link.title}
              </a>
            </li>
          ))}
        </SectionsList>
        <SocialsList>
          {socialsData.map((social, index) => (
            <li key={index}>
              <a
                href={social.href}
                target={social?.target ? social.target : ""}
              >
                {social.icon}
              </a>
            </li>
          ))}
        </SocialsList>
      </Container>
    </>
  );
}

export default MobileNav;
