import { useEffect, useState } from "react";
import { FiLink, FiSend } from "react-icons/fi";
import styled from "styled-components";
import Footer from "./components/footer/footer";
import Hero from "./components/hero/hero";
import ProjectList from "./components/project-list/project-list";
// Components:
import Cta from "./components/buttons/cta/cta";
import Header from "./components/header/header";
import MobileNav from "./components/mobile-nav/mobile-nav";
import NameTag from "./components/name-tag/name-tag";
import TechnologiesList from "./components/technologies-list/technologies-list";
// Assets:
import "./App.css";
import projects from "./assets/data/projects";
// Constants:
import { colors, fonts } from "./constants/constants";
import useEscape from "./hooks/useEscape";
import useScrollOverflow from "./hooks/useHideOverflow";
import useHideOverflow from "./hooks/useHideOverflow";

const Container = styled.div`
  position: relative;
`;

const Filter = styled.div.attrs((props: { menuVisible: boolean }) => props)`
  width: 100%;
  height: 100%;

  position: fixed;
  z-index: 100;

  display: ${(props) => (props.menuVisible ? "block" : "none")};

  background-color: rgba(255, 255, 255, 0.5);
`;

const Content = styled.div`
  max-width: 70rem;
  padding: 0 2rem;
  margin: 0 auto;

  p {
    font-family: ${fonts.primary.medium} !important;
  }
  
`;

const Spacer = styled.div`
  height: 3rem;
`;

const Title = styled.div`
max-width: 70rem;
margin: 0 auto;

h1 {


  position: relative;


  margin-bottom: 2rem;
  padding: .5rem 2rem;

  display: inline-block;

  background-color: ${colors.primary};
  
  
  
  font-family: ${fonts.secondary.regular};
  line-height: 1;
  color: white;

  animation: 10s ease-in-out infinite alternate borderRadiusTitleAllSidesSmall;

  

  @media (min-width: 70rem) {
    animation: 10s ease-in-out infinite alternate borderRadiusTitleAllSides;
  }
}
`;

//? Projects
const ProjectsContainer = styled.div`
  max-width: 70rem;

  margin: 0 auto;
  // margin-bottom: 10rem;
  padding: 0 2rem;
`;

//? Professional Growth
const ButtonGroup = styled.ul`
  max-width: 40rem;

  margin: 0 auto;
  margin-bottom: 2rem;
  padding-left: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  list-style-type: none;

  @media (min-width: 48rem) {
    flex-direction: row;
  }
`;

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEscape(() => setIsMenuOpen(false));
  // useHideOverflow(isOverflowHidden);
  // const [isOverflowHidden, setIsOverflowHidden] = useHideOverflow();

  const toggleMenu = () => {
    console.log("toggleMenu");
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Container>
      <Filter menuVisible={isMenuOpen} onClick={toggleMenu} />
      <Header isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      <MobileNav hideMenu={toggleMenu} active={isMenuOpen} />
      <Hero />
      <NameTag />
      <Spacer id="projects" />
      <Title><h1>Project Overview</h1></Title>
      <ProjectsContainer>
        <ProjectList projects={projects} menuOpen={isMenuOpen} />
      </ProjectsContainer>
      <Spacer id="professional-growth" />
      <Title>
        <h1>
          Professional Growth
          </h1>
          </Title>
      <Content>
        <p>
          In general I'm looking for a front-end developer job, while also
          improving my Javascript-skills in the back-end.
        </p>
        <p>
          I love exploring new technologies, and like to <i>put my teeth</i> in
          a technical problem, not always taking the easy route.
        </p>
        <p>
          If you're interested in offering me a job, take a look at my CV or hit
          me up.
        </p>
        <ButtonGroup>
          <li>
            <Cta
              href="https://cv.jeroendewelde.be"
              Icon={FiLink}
              text="Look at my CV"
              target="_blank"
            />
          </li>
          <li>
            <Cta
              href="mailto:Jeroen.Dewelde@gmail.com"
              Icon={FiSend}
              text="Send me an email"
            />
          </li>
        </ButtonGroup>
      </Content>
      <Spacer id="technologies" />
      <Title><h1>Technologies</h1></Title>
      <Content>
        <TechnologiesList />
      </Content>
      <Footer />
    </Container>
  );
}

export default App;
