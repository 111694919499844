import styled from "styled-components";

const Container = styled.div.attrs((props: { active: boolean }) => props)`
  display: block;
  width: 1.5rem;
  height: 1.2rem;
  position: relative;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #fff;
    border-radius: 3px;
    opacity: 1;
    left: 0;

    transition: all 0.2s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-child(3) {
      bottom: 0;
    }
  }
  &:hover span {
    background-color: #fff;
  }

  ${(props) =>
    props.active
      ? `span:nth-child(1) {
    top: 50%;
    transform: translateY(-50%);
    transform: rotate(135deg);
  }

  span:nth-child(2) {
    opacity: 0;
    left: -2rem;
  }

  span:nth-child(3) {
    top: 50%;
    transform: translateY(-50%);
    transform: rotate(-135deg);
  }`
      : ""}
`;

interface MenuTogglerProps {
  active: boolean;
  onClick: () => void;
}
function MenuToggler({ onClick, active }: MenuTogglerProps) {
  return (
    <Container active={active} onClick={onClick}>
      <span></span>
      <span></span>
      <span></span>
    </Container>
  );
}

export default MenuToggler;
