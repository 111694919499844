const general = {
  technologies: [
    "NextJS",
    "NestJS",
    "Expo",
    // "React",
    "React Query",
    "Typescript",
    // "ES6",
    "Styled Components",
    "Sass",
    // "PostgreSQL",
    // "GraphQL",
    "Nx",
    "Formik/Yup",
    // "PHP",
    "Wordpress",
    // "Advanced Custom Fields &amp; Gutenberg",
    "Strapi",
    "Firebase/Firestore",
  ],
  technologiesImprove: [
    "React Native",
    "NestJS",
    "VueJS",
    "SvelteKit",
    "Keystone",
    "Jest",
    "Storybook",
    "...",
  ],
};

export default general;
