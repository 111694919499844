import styled from "styled-components";
import { colors, fonts } from "../../constants/constants";
import MenuToggler from "../buttons/menu-toggler/menu-toggler";
import MobileNav from "../mobile-nav/mobile-nav";

const Nav = styled.div`
  height: 3rem;
  width: 100%;

  position: fixed;
  z-index: 200;

  > nav {
    max-width: 70rem;

    margin: 0 auto;
    padding: 1rem 2rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: ${colors.primary};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);

    font-family: ${fonts.secondary.regular};
    color: white;
  }
`;
const Brand = styled.a`
  text-decoration: none;
  color: white;
`;

interface HeaderProps {
  isMenuOpen: boolean;
  toggleMenu: () => void;
}
function Header({ isMenuOpen, toggleMenu }: HeaderProps) {
  return (
    <>
      <Nav>
        <nav>
          <Brand href="#">JD</Brand>
          <MenuToggler onClick={toggleMenu} active={isMenuOpen} />
        </nav>
      </Nav>

      <MobileNav hideMenu={toggleMenu} active={isMenuOpen} />
    </>
  );
}

export default Header;
