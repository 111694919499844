import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import {
  FiChevronLeft,
  FiChevronRight,
  FiGithub,
  FiGlobe,
  FiMaximize2,
  FiMinimize2,
  FiYoutube,
  FiChevronsDown,
  FiChevronsUp
} from "react-icons/fi";
import styled from "styled-components";
import { colors, fonts } from "../../constants/constants";
import useArrowKeys from "../../hooks/useArrowKeys";
import useHideOverflow from "../../hooks/useHideOverflow";
import { Project } from "../../types/project";

const Container = styled.div`
  width: 100%;

  // margin-bottom: 8rem;
  margin-bottom: 4rem;

  display: flex;
  flex-direction: column-reverse;
  gap: 2rem;

  // border: solid 2px red;

  @media (min-width: 64rem) {
    height: 35rem;

    flex-direction: row;
    align-items: stretch;
  }

  // > div {
  //   @media (min-width: 64rem) {
  //     width: 50%;
  //     height: auto;
  //   }
  // }

  @media (min-width: 64rem) {
    margin-bottom: 8rem;
    &.open {
    margin-bottom: 4rem;
    height: 25rem;

    .gallery {
      width: 10rem;
      // position: relative;
      border: none;
      min-height: unset;
      

      .gallery-controls {
        display: none;
      }

      .fullscreen-button {
        display: none;
      }

      .previews {
        display: none;
      }
    }

    .selected-image {
      width: 20rem;
      z-index: -1;
      opacity: .8;
      border: none;
      // position: absolute;
      // border: solid 2px green;
      // height: 100%;
      // min-height: auto;

      transition: opacity 0.2s ease-in-out;
    }

    .project-info {
      width: 100%;

      padding: 1rem;

      background-color: rgba(251, 251, 249, 0.9);

      transition: background-color 0.2s ease-in-out;

      .team {
        display: none;
      }

      .project-content {
        height: 5rem;

        overflow: hidden;
        margin-bottom: auto;
      }
    }

    .technologies {
      margin-top: auto;
    }

    &:hover {
      .selected-image {
        opacity: .9;
      }

      .project-info {
        background-color: rgba(251, 251, 249, 1);
      }
    }
  }
}
`;

const Gallery = styled.div.attrs((props: { fullscreen: boolean }) => props)`
  height: 15rem;
  // border: solid 2px red;

  display: flex;
  flex-direction: column;

  @media (min-width: 48rem) {
    height: 25rem;
  }

  @media (min-width: 64rem) {
    width: 50%;
    height: 100%;
    min-height: 30rem;
  }

  

  ${(props) => props.fullscreen && (
`
    // on full screen:
    // border: solid 2px PURPLE;
    position: fixed;
    top: calc( 3rem + 4px) ;
    left: 0;
    background-color: #FFF;
    width: 100% !important;
    height: calc( 100vh - 3rem ) !important;
    z-index: 100;
    padding-bottom: 3rem;
    `
  )}
`;

const SelectedImage = styled.div.attrs(
  (props: { translateX: any; first: boolean }) => props
)`
  height: 100%;

  position: relative;

  display: flex;
  flex-direction: row;

  overflow: hidden;

  border: solid 2px ${colors.primary};
  border-top-left-radius: 5px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: ${(props) => (props.first ? "0" : "5px")};
`;

const SelectedInnerImage = styled(motion.div)`
  &:nth-child(1) {
    width: 100%;

    display: flex;
    flex-shrink: 0;

    // transition: 0.2s ease-out;

    > div {
      flex-shrink: 0;
    }
  }
`;

const Image = styled.div`
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
`;

const GalleryControls = styled.div`
  width: 100%;
  // height: calc( 100% - 42px );
  height: 100%;

  position: absolute;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // border: solid 2Px red;
  // top: 42px;
`;

const FullScreenButton = styled.div.attrs((props: { fullscreen: boolean }) => props)`
  width: 42px;
  height: 42px;
  background-color: red;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  
  // border: solid 2px ${colors.primary};
  background-color: ${(props) => (props.fullscreen ? colors.primary : "rgba(255, 255, 255, 0.5)")};
  color: ${(props) => (props.fullscreen ? "#FFF" : colors.primary)};
  // background-color: transparent;
  border-top-right-radius: 5px;
  // border-bottom-left-radius: 0;
  // border-bottom-right-radius: 0;

  cursor: pointer;

  svg {
    transition: 0.1s ease-in-out;
    font-size: 1rem;
  }

  &:hover {
    background-color: ${(props) => (props.fullscreen ? colors.primary : "rgba(255, 255, 255, 0.9)")};
    

    svg {
      font-size: 1.2rem;
    }
  }
`;

const Arrow = styled.a`
  height: 100%;
  width: 42px;

  display: flex;
  justify-content: center;
  align-items: center;
  // padding-bottom: 42px;

  background-color: rgba(255, 255, 255, 0.5);
  // border: solid 3px green;
  transition: 0.2s ease-out;

  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }

  svg {
    transition: 0.2s ease-out;
    font-size: 2rem;
    color: ${colors.primaryLight};
  }

  &:hover {
    svg {
      color: ${colors.primary};
    }
  }
`;

const Previews = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    width: 50px;
    height: 50px;
  }
`;

const ImagePreview = styled.div.attrs((props: { selected: boolean }) => props)`
  width: 100%;
  height: 100%;

  overflow: hidden;

  border: solid 2px
    ${(props) => (props.selected ? colors.primary : "transparent")};
  border-top: transparent;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  opacity: ${(props) => (props.selected ? 1 : 0.5)};

  transition: 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    cursor: pointer;
  }
`;

const ProjectInfo = styled.div`
  display: flex;
  flex-direction: column;

  // padding: 0 1rem;
  padding: 0;

  color: ${colors.grey};

  @media (min-width: 64rem) {
    width: 50%;
    height: auto;
  }
`;

const ProjectTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  > div {
    &:nth-child(1) {
      display: flex;
      flex-direction: column;

      h3 {
        margin: 0;
        padding-bottom: 0.5rem;

        font-family: ${fonts.primary};
        font-size: 1.5rem;
        font-weight: 600;
        color: ${colors.primary};
      }
    }
  }
`;

const ProjectLinks = styled.ul`
  display: flex;
  flex-direction: row;

  margin: 0;
  padding-left: 0;

  list-style-type: none;

  li {
    margin-right: 1rem;

    a,
    a:visited {
      color: ${colors.primaryLight};

      transition: 0.2s ease-out;

      &:hover,
      &:active,
      &:focus {
        color: ${colors.primary};
      }
    }
  }
`;

const Calendar = styled.div`
  position: relative;

  width: 60px;
  height: 100%;

  margin: 0;

  display: flex;
  flex-direction: column;

  border: solid 1px ${colors.calendarRed};
  border-radius: 5px;

  font-size: 0.75rem;
  font-weight: 600;
  color: ${colors.grey};

  > div {
    text-align: center;

    &:nth-child(1) {
      padding: 5px 0;

      background-color: ${colors.calendarRed};

      font-family: ${fonts.primary};
      text-transform: uppercase;
      color: white;
    }
    &:nth-child(2) {
      padding: 7.5px 0;
      font-family: ${fonts.secondary};
    }
  }

  &::before,
  &::after {
    width: 6px;
    height: 12px;

    position: absolute;
    top: 0;
    content: "";
    transform: translate(-50%, -60%);

    background-color: #fff;

    border-radius: 5px;
    border: solid 1px ${colors.calendarRed};
  }
  &::before {
    left: 25%;
  }
  &::after {
    left: 75%;
  }
`;

const ProjectContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    overflow-y: hidden;
  }
`;

const Technologies = styled.div`
  // margin-bottom: 50px;

  ul {
    margin: 0;
    margin-top: 1rem;
    padding-left: 0;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    list-style-type: none;

    li {
      margin-right: 1rem;
      margin-top: 0.5rem;
      padding: 4px 8px;

      border: solid 1px ${colors.primary};
      border-radius: 8px;

      font-family: ${fonts.primary};
      color: ${colors.primary};
    }
  }
`;

const Team = styled.div`
  margin-top: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-size: 0.75rem;
    font-weight: 500;
    font-family: ${fonts.primary};
  }
`;
const TeamList = styled.ul`
  margin: 0;
  padding-left: 0;

  display: flex;
  flex-direction: row;

  list-style-type: none;
`;

const TeamMember = styled.li`
  width: 48px;
  height: 48px;

  overflow: hidden;

  margin-left: 1rem;
  margin-top: auto;

  background-color: ${colors.primaryLight};

  border: solid 2px ${colors.primary};
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

const ExpandContainer = styled.div`
height: 50px;

align-self: flex-end;
`;

const Expand = styled.a`
cursor: pointer;

margin-top: 1rem;
margin-left: auto;

display: none;
@media (min-width: 48rem) {
display: flex;
}
flex-direction: row;
align-items: center;

font-size: 1.2rem;

.tooltip {
  transition: 0.2s ease-in-out;
  p {
    margin: 0;

    color: ${colors.primaryLight};
    font-family: ${fonts.secondary.light};

    transition: 0.2s ease-in-out;
  }
}

svg {
  margin-top: 4px;

  color: ${colors.primaryLight};
  font-size: 1rem;

  transition: 0.2s ease-in-out;
}

&:hover {
  p {
    color: ${colors.primary};
  }
  svg {
    color: ${colors.primary};

    transform: translateY(2px);
  }
}
`;

interface ProjectItemProps extends Project {
  reset?: boolean;
  // function from parent
  enableFullScreen: () => void;
  fullscreen: boolean;
}

function ProjectItem(props: ProjectItemProps) {
  const [selectedImage, setSelectedImage] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  

  // useHideOverflow(props.fullscreen);

  useEffect(() => {
    if (props.reset) {
      setSelectedImage(0);
      setTranslateX(0);
      setIsExpanded(false);
    }
  }, [props.reset]);

  const previousImage = () => {
    if (selectedImage === 0) {
      setSelectedImage(props.images.length - 1);
      setTranslateX(-100 * (props.images.length - 1));
    } else {
      setSelectedImage(selectedImage - 1);
      setTranslateX(translateX + 100);
    }
  };

  const nextImage = () => {
    if (selectedImage === props.images.length - 1) {
      setSelectedImage(0);
      setTranslateX(0);
    } else {
      setSelectedImage(selectedImage + 1);
      setTranslateX(translateX - 100);
    }
  };

  const setImageFromPreview = (index: number) => {
    setSelectedImage(index);
    setTranslateX(-100 * index);
  };


  return (
    <Container className={isExpanded ? "" : "open"}>
      <Gallery fullscreen={props.fullscreen} className="gallery">
        <SelectedImage
          // translateX={translateX}
          first={selectedImage == 0 ? true : false}
          className="selected-image"
        >
          <SelectedInnerImage animate={{ x: translateX + "%" }} transition={{
            // type: "spring", stiffness: 100, damping: 30
            type: "spring", stiffness: 50, damping: 10,
            duration: 0
          }}>
            {props.images.map((image, index) => (
              <Image>
                <img src={image.url} alt="profile picture" />
              </Image>
            ))}
          </SelectedInnerImage>
          <GalleryControls className="gallery-controls">
            <Arrow onClick={previousImage}>
              <FiChevronLeft />
            </Arrow>
            <Arrow onClick={nextImage}>
              <FiChevronRight />
            </Arrow>
          </GalleryControls>
          <FullScreenButton onClick={() => props.enableFullScreen()} fullscreen={props.fullscreen} className="fullscreen-button">
            {props.fullscreen ? <FiMinimize2 /> : <FiMaximize2 />}
            
          </FullScreenButton>
        </SelectedImage>
        <Previews className="previews">
          {props?.previews.map((image, index) => (
            <ImagePreview
              selected={index === selectedImage}
              onClick={() => setImageFromPreview(index)}
            >
              <img src={image.url} />
            </ImagePreview>
          ))}
        </Previews>
      </Gallery>
      <ProjectInfo className="project-info">
        <ProjectTitle>
          <div>
            <h3>{props.title}</h3>
            {props.links && props?.links?.length > 0 ? (
              <ProjectLinks>
                {props.links.map((link, index) => (
                  <li>
                    <a
                      href={link.url}
                      target="_blank"
                      title={link.alt ? link.alt : ""}
                    >
                      {link.type === "github" && <FiGithub />}
                      {link.type === "youtube" && <FiYoutube />}
                      {link.type === "website" && <FiGlobe />}
                    </a>
                  </li>
                ))}
              </ProjectLinks>
            ) : (
              <></>
            )}
          </div>
          <Calendar>
            <div>{props.date.month}</div>
            <div>{props.date.year}</div>
          </Calendar>
        </ProjectTitle>
        <ProjectContent className="project-content">
          <div>
            <p dangerouslySetInnerHTML={{ __html: props.description }} />
          </div>

          {props?.team && props?.team?.length > 0 && (
            <Team className="team">
              <h4>Collaborated with:</h4>
              <TeamList>
                {props.team.map((person, i) => (
                  <TeamMember key={i}>
                    {person.link ? (
                      <a
                        href={person.link.url}
                        target="_blank"
                        title={person.link.title ? person.link.title : ""}
                      >
                        <img
                          src={person.image}
                          alt={person?.name ? person.name : ""}
                        />
                      </a>
                    ) : (
                      <img
                        src={person.image}
                        alt={person?.name ? person.name : ""}
                      />
                    )}
                  </TeamMember>
                ))}
              </TeamList>
            </Team>
          )}
        </ProjectContent>
        <Technologies className="technologies">
          <ul>
            {props.technologies.map((technology) => (
              <li>{technology}</li>
            ))}
          </ul>
        </Technologies>
      <ExpandContainer>
      <Expand title="expand this project" onClick={() => setIsExpanded(!isExpanded)}>
    <div className="tooltip">
      <p>
        {isExpanded ? "collapse" : "expand"} this project
        </p>
    </div>
    {isExpanded ? <FiChevronsUp /> : <FiChevronsDown />}
    
  </Expand>
  </ExpandContainer>
  </ProjectInfo>
    </Container>
  );
}

export default ProjectItem;
