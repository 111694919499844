import { useEffect } from "react";

function useHideOverflow(overflow: boolean) {
  useEffect(() => {
    if (overflow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [overflow]);
}

export default useHideOverflow;
