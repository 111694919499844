import { Project } from "../../types/project";
// People
import JamieLeeHartPicture from "../../assets/images/people/jamie-lee_hart.png";
import JanDeschachtPicture from "../../assets/images/people/jan_deschacht.png";

// 1.Dots:
import Dots1Image from "../../assets/images/projects/dots/1.png";
// Previews
import Dots1ImagePreview from "../../assets/images/projects/dots/preview/1.png";
// 2.Xplore:
import Xplore1Image from "../../assets/images/projects/xplore/1.png";
import Xplore2Image from "../../assets/images/projects/xplore/2.png";
import Xplore3Image from "../../assets/images/projects/xplore/3.png";
import Xplore4Image from "../../assets/images/projects/xplore/4.png";
import Xplore5Image from "../../assets/images/projects/xplore/5.png";
import Xplore6Image from "../../assets/images/projects/xplore/6.png";
import Xplore7Image from "../../assets/images/projects/xplore/7.png";
// Previews
import Xplore1ImagePreview from "../../assets/images/projects/xplore/preview/1.png";
import Xplore2ImagePreview from "../../assets/images/projects/xplore/preview/2.png";
import Xplore3ImagePreview from "../../assets/images/projects/xplore/preview/3.png";
import Xplore4ImagePreview from "../../assets/images/projects/xplore/preview/4.png";
import Xplore5ImagePreview from "../../assets/images/projects/xplore/preview/5.png";
import Xplore6ImagePreview from "../../assets/images/projects/xplore/preview/6.png";
import Xplore7ImagePreview from "../../assets/images/projects/xplore/preview/7.png";
// 3.PGM-platform
import PgmPlatform1Image from "../../assets/images/projects/pgm-platform/1.png";
import PgmPlatform10Image from "../../assets/images/projects/pgm-platform/10.png";
import PgmPlatform2Image from "../../assets/images/projects/pgm-platform/2.png";
import PgmPlatform3Image from "../../assets/images/projects/pgm-platform/3.png";
import PgmPlatform4Image from "../../assets/images/projects/pgm-platform/4.png";
import PgmPlatform5Image from "../../assets/images/projects/pgm-platform/5.png";
import PgmPlatform6Image from "../../assets/images/projects/pgm-platform/6.png";
import PgmPlatform7Image from "../../assets/images/projects/pgm-platform/7.png";
import PgmPlatform8Image from "../../assets/images/projects/pgm-platform/8.png";
import PgmPlatform9Image from "../../assets/images/projects/pgm-platform/9.png";
// Previews
import PgmPlatform1ImagePreview from "../../assets/images/projects/pgm-platform/preview/1.png";
import PgmPlatform10ImagePreview from "../../assets/images/projects/pgm-platform/preview/10.png";
import PgmPlatform2ImagePreview from "../../assets/images/projects/pgm-platform/preview/2.png";
import PgmPlatform3ImagePreview from "../../assets/images/projects/pgm-platform/preview/3.png";
import PgmPlatform4ImagePreview from "../../assets/images/projects/pgm-platform/preview/4.png";
import PgmPlatform5ImagePreview from "../../assets/images/projects/pgm-platform/preview/5.png";
import PgmPlatform6ImagePreview from "../../assets/images/projects/pgm-platform/preview/6.png";
import PgmPlatform7ImagePreview from "../../assets/images/projects/pgm-platform/preview/7.png";
import PgmPlatform8ImagePreview from "../../assets/images/projects/pgm-platform/preview/8.png";
import PgmPlatform9ImagePreview from "../../assets/images/projects/pgm-platform/preview/9.png";
// 4. LifeLog
import LifeLog1Image from "../../assets/images/projects/lifelog/1.png";
import LifeLog10Image from "../../assets/images/projects/lifelog/10.png";
import LifeLog11Image from "../../assets/images/projects/lifelog/11.png";
import LifeLog2Image from "../../assets/images/projects/lifelog/2.png";
import LifeLog3Image from "../../assets/images/projects/lifelog/3.png";
import LifeLog4Image from "../../assets/images/projects/lifelog/4.png";
import LifeLog5Image from "../../assets/images/projects/lifelog/5.png";
import LifeLog6Image from "../../assets/images/projects/lifelog/6.png";
import LifeLog7Image from "../../assets/images/projects/lifelog/7.png";
import LifeLog8Image from "../../assets/images/projects/lifelog/8.png";
import LifeLog9Image from "../../assets/images/projects/lifelog/9.png";
// Previews
import LifeLog1ImagePreview from "../../assets/images/projects/lifelog/preview/1.png";
import LifeLog10ImagePreview from "../../assets/images/projects/lifelog/preview/10.png";
import LifeLog11ImagePreview from "../../assets/images/projects/lifelog/preview/11.png";
import LifeLog2ImagePreview from "../../assets/images/projects/lifelog/preview/2.png";
import LifeLog3ImagePreview from "../../assets/images/projects/lifelog/preview/3.png";
import LifeLog4ImagePreview from "../../assets/images/projects/lifelog/preview/4.png";
import LifeLog5ImagePreview from "../../assets/images/projects/lifelog/preview/5.png";
import LifeLog6ImagePreview from "../../assets/images/projects/lifelog/preview/6.png";
import LifeLog7ImagePreview from "../../assets/images/projects/lifelog/preview/7.png";
import LifeLog8ImagePreview from "../../assets/images/projects/lifelog/preview/8.png";
import LifeLog9ImagePreview from "../../assets/images/projects/lifelog/preview/9.png";
// 5. Cookbook
import CookBook1Image from "../../assets/images/projects/cook-book/1.png";
import CookBook2Image from "../../assets/images/projects/cook-book/2.png";
import CookBook3Image from "../../assets/images/projects/cook-book/3.png";
import CookBook4Image from "../../assets/images/projects/cook-book/4.png";
import CookBook5Image from "../../assets/images/projects/cook-book/5.png";
import CookBook6Image from "../../assets/images/projects/cook-book/6.png";
import CookBook7Image from "../../assets/images/projects/cook-book/7.png";
import CookBook8Image from "../../assets/images/projects/cook-book/8.png";
import CookBook9Image from "../../assets/images/projects/cook-book/9.png";
// Previews
import CookBook1ImagePreview from "../../assets/images/projects/cook-book/preview/1.png";
import CookBook2ImagePreview from "../../assets/images/projects/cook-book/preview/2.png";
import CookBook3ImagePreview from "../../assets/images/projects/cook-book/preview/3.png";
import CookBook4ImagePreview from "../../assets/images/projects/cook-book/preview/4.png";
import CookBook5ImagePreview from "../../assets/images/projects/cook-book/preview/5.png";
import CookBook6ImagePreview from "../../assets/images/projects/cook-book/preview/6.png";
import CookBook7ImagePreview from "../../assets/images/projects/cook-book/preview/7.png";
import CookBook8ImagePreview from "../../assets/images/projects/cook-book/preview/8.png";
import CookBook9ImagePreview from "../../assets/images/projects/cook-book/preview/9.png";
// 6. Portfolio2021
import Portfolio20211Image from "../../assets/images/projects/portfolio-2021/1.png";
import Portfolio20212Image from "../../assets/images/projects/portfolio-2021/2.png";
import Portfolio20213Image from "../../assets/images/projects/portfolio-2021/3.png";
import Portfolio20214Image from "../../assets/images/projects/portfolio-2021/4.png";
// Previews
import Portfolio20211ImagePreview from "../../assets/images/projects/portfolio-2021/preview/1.png";
import Portfolio20212ImagePreview from "../../assets/images/projects/portfolio-2021/preview/2.png";
import Portfolio20213ImagePreview from "../../assets/images/projects/portfolio-2021/preview/3.png";
import Portfolio20214ImagePreview from "../../assets/images/projects/portfolio-2021/preview/4.png";
// 7. My Desk
import MyDesk1Image from "../../assets/images/projects/my-desk/1.png";
import MyDesk2Image from "../../assets/images/projects/my-desk/2.png";
import MyDesk3Image from "../../assets/images/projects/my-desk/3.png";
import MyDesk4Image from "../../assets/images/projects/my-desk/4.png";
// Previews
import MyDesk1ImagePreview from "../../assets/images/projects/my-desk/preview/1.png";
import MyDesk2ImagePreview from "../../assets/images/projects/my-desk/preview/2.png";
import MyDesk3ImagePreview from "../../assets/images/projects/my-desk/preview/3.png";
import MyDesk4ImagePreview from "../../assets/images/projects/my-desk/preview/4.png";
// 8. Movie Application
// 9. SillyWalker
import SillyWalkerLandingPage1Image from "../../assets/images/projects/silly-walker/landing-page/1.png";
import SillyWalkerLandingPage2Image from "../../assets/images/projects/silly-walker/landing-page/2.png";
import SillyWalkerLandingPage3Image from "../../assets/images/projects/silly-walker/landing-page/3.png";
import SillyWalkerLandingPage4Image from "../../assets/images/projects/silly-walker/landing-page/4.png";
import SillyWalkerLandingPage5Image from "../../assets/images/projects/silly-walker/landing-page/5.png";
import SillyWalkerLandingPage6Image from "../../assets/images/projects/silly-walker/landing-page/6.png";
import SillyWalkerLandingPage7Image from "../../assets/images/projects/silly-walker/landing-page/7.png";
import SillyWalkerLandingPage8Image from "../../assets/images/projects/silly-walker/landing-page/8.png";
import SillyWalkerLandingPage9Image from "../../assets/images/projects/silly-walker/landing-page/9.png";
import SillyWalkerPrototype1Image from "../../assets/images/projects/silly-walker/prototype/1.png";
import SillyWalkerPrototype2Image from "../../assets/images/projects/silly-walker/prototype/2.png";
import SillyWalkerPrototype3Image from "../../assets/images/projects/silly-walker/prototype/3.png";
// Previews
import SillyWalkerLandingPage1ImagePreview from "../../assets/images/projects/silly-walker/landing-page/preview/1.png";
import SillyWalkerLandingPage2ImagePreview from "../../assets/images/projects/silly-walker/landing-page/preview/2.png";
import SillyWalkerLandingPage3ImagePreview from "../../assets/images/projects/silly-walker/landing-page/preview/3.png";
import SillyWalkerLandingPage4ImagePreview from "../../assets/images/projects/silly-walker/landing-page/preview/4.png";
import SillyWalkerLandingPage5ImagePreview from "../../assets/images/projects/silly-walker/landing-page/preview/5.png";
import SillyWalkerLandingPage6ImagePreview from "../../assets/images/projects/silly-walker/landing-page/preview/6.png";
import SillyWalkerLandingPage7ImagePreview from "../../assets/images/projects/silly-walker/landing-page/preview/7.png";
import SillyWalkerLandingPage8ImagePreview from "../../assets/images/projects/silly-walker/landing-page/preview/8.png";
import SillyWalkerLandingPage9ImagePreview from "../../assets/images/projects/silly-walker/landing-page/preview/9.png";
import SillyWalkerPrototype1ImagePreview from "../../assets/images/projects/silly-walker/prototype/preview/1.png";
import SillyWalkerPrototype2ImagePreview from "../../assets/images/projects/silly-walker/prototype/preview/2.png";
import SillyWalkerPrototype3ImagePreview from "../../assets/images/projects/silly-walker/prototype/preview/3.png";
// 10. Aisukurimu
import Aisukurimu1Image from "../../assets/images/projects/aisukurimu/1.png";
import Aisukurimu2Image from "../../assets/images/projects/aisukurimu/2.png";
import Aisukurimu3Image from "../../assets/images/projects/aisukurimu/3.png";
import Aisukurimu4Image from "../../assets/images/projects/aisukurimu/4.png";
// Previews
import Aisukurimu1ImagePreview from "../../assets/images/projects/aisukurimu/preview/1.png";
import Aisukurimu2ImagePreview from "../../assets/images/projects/aisukurimu/preview/2.png";
import Aisukurimu3ImagePreview from "../../assets/images/projects/aisukurimu/preview/3.png";
import Aisukurimu4ImagePreview from "../../assets/images/projects/aisukurimu/preview/4.png";
// 11. Amor
import Amor1Image from "../../assets/images/projects/amor/1.png";
import Amor2Image from "../../assets/images/projects/amor/2.png";
// Previews
import Amor1ImagePreview from "../../assets/images/projects/amor/preview/1.png";
import Amor2ImagePreview from "../../assets/images/projects/amor/preview/2.png";
// 12. EMPTY

const Projects: Project[] = [
  {
    title: "Xplore",
    description: `
    <p>As my final project, this was (in idea) a simple <b>travel planner</b> to add friends to your trip, modify this trip & have a clear overview on your schedule for the trip.</p>
    <p>The project was create inside an <b>Nx workspace</b>, consisting of 2 apps & multiple <i>shared libraries</i>.</p>
    <p>The Back-end was created with NestJS, the front-end with NextJS.</p>
    <p>The shared libraries were for f.e. custom components extended on Material UI & Formik/Yup, creating a connection between database(s) and the apps.</p>
    <p>Creating this structure was a personal choice, pushing typescript & <i>separation of concerns</i> to its limits.</p>`,
    technologies: [
      "Nx",
      "NestJS",
      "NextJS",
      "Typescript",
      "React Query",
      "GraphQL",
      "Formik",
      "Yup",
      "Material UI",
      "PostgreSQL",
      "TypeORM",
      "Styled Components",
    ],
    images: [
      {
        url: Xplore1Image,
        alt: "screenshot",
      },
      {
        url: Xplore2Image,
        alt: "screenshot",
      },
      {
        url: Xplore3Image,
        alt: "screenshot",
      },
      {
        url: Xplore4Image,
        alt: "screenshot",
      },
      {
        url: Xplore5Image,
        alt: "screenshot",
      },
      {
        url: Xplore6Image,
        alt: "screenshot",
      },
      {
        url: Xplore7Image,
        alt: "screenshot",
      },
    ],
    previews: [
      {
        url: Xplore1ImagePreview,
      },
      {
        url: Xplore2ImagePreview,
      },
      {
        url: Xplore3ImagePreview,
      },
      {
        url: Xplore4ImagePreview,
      },
      {
        url: Xplore5ImagePreview,
      },
      {
        url: Xplore6ImagePreview,
      },
      {
        url: Xplore7ImagePreview,
      },
    ],
    links: [
      {
        url: "https://github.com/pgm-jeroendewelde/xplore",
        alt: "Github Repository",
        type: "github",
      },
      {
        url: "https://youtu.be/GH4pA6ZPGN0",
        alt: "Youtube Screencast",
        type: "youtube",
      },
    ],
    date: {
      month: "June",
      year: 2022,
    },
  },
  {
    title: "PGM Platform",
    description: `
    <p>In this team-project, our client was <i>Artevelde</i> itself & we had to create a modern <b>website with back-office</b> to promote our own associate degree “Programming”. </p>
    <p>It was possible to add courses, projects, teachers & students and added these relations & calling all this data in the front-end.</p>
    <p>After the brainstorm, I mainly focussed on the back-end & creating the back-office. My team-mate created the website & added the smooth animations, transitions, …</p>
`,
    technologies: [
      "NextJS",
      "NestJS",
      "Typescript",
      "PostgreSQL",
      "TypeORM",
      "GraphQL",
      "PassportJS",
      "Apollo",
      "Formik",
      "Yup",
      "Styled Components",
      "Material UI",
    ],
    images: [
      {
        url: PgmPlatform1Image,
        alt: "screenshot",
      },
      {
        url: PgmPlatform2Image,
        alt: "screenshot",
      },
      {
        url: PgmPlatform3Image,
        alt: "screenshot",
      },
      {
        url: PgmPlatform4Image,
        alt: "screenshot",
      },
      {
        url: PgmPlatform5Image,
        alt: "screenshot",
      },
      {
        url: PgmPlatform6Image,
        alt: "screenshot",
      },
      {
        url: PgmPlatform7Image,
        alt: "screenshot",
      },
      {
        url: PgmPlatform8Image,
        alt: "screenshot back-office",
      },
      {
        url: PgmPlatform9Image,
        alt: "screenshot back-office",
      },
      {
        url: PgmPlatform10Image,
        alt: "screenshot back-office",
      },
    ],
    previews: [
      {
        url: PgmPlatform1ImagePreview,
      },
      {
        url: PgmPlatform2ImagePreview,
      },
      {
        url: PgmPlatform3ImagePreview,
      },
      {
        url: PgmPlatform4ImagePreview,
      },
      {
        url: PgmPlatform5ImagePreview,
      },
      {
        url: PgmPlatform6ImagePreview,
      },
      {
        url: PgmPlatform7ImagePreview,
      },
      {
        url: PgmPlatform8ImagePreview,
      },
      {
        url: PgmPlatform9ImagePreview,
      },
      {
        url: PgmPlatform10ImagePreview,
      },
    ],
    links: [
      {
        url: "https://github.com/pgm-jeroendewelde/pgm-platform-client",
        alt: "Github Repository Client",
        type: "github",
      },
      {
        url: "https://github.com/pgm-jeroendewelde/pgm-platform-server",
        alt: "Github Repository Server",
        type: "github",
      },
      {
        url: "https://www.youtube.com/watch?v=stfBDkT7k9I",
        alt: "Youtube Screencast Website",
        type: "youtube",
      },
      {
        url: "https://www.youtube.com/watch?v=3ZgbotfNZLU",
        alt: "Youtube Screencast Back Office",
        type: "youtube",
      },
    ],
    date: {
      month: "JAN",
      year: 2022,
    },
    team: [
      {
        name: "Jamie-Lee Hart",
        image: JamieLeeHartPicture,
        link: {
          url: "https://pgm-jamihart.github.io/Portfolio",
          title: "Visit Jamie-Lee's portfolio",
        },
      },
    ],
  },
  {
    title: "LifeLog",
    description: `
    <p>In this <b>COIL</b>-project, we were with 2 Artevelde-students for development & 2 students from Seneca Canada for creating the user manual.</p>
    <p>In stead of creating a <i>webshop again</i>.. We decided to create an <b>web-app/platform for the elderly</b> to store and easily access pictures, agenda-items, wishlist-items, …</p>
    <p>When we made this choice, we had to focus on a BIG and <i>easily accessible UI</i>. Making it possible to <i>link</i> some things <i>to family-members</i> made it a challenge in connecting the data</p>
    `,
    technologies: [
      "Typescript",
      "Apollo",
      "Formik",
      "GraphQL",
      "Styled Components",
      "NestJS",
      "TypeORM",
      "PassportJS",
    ],
    images: [
      {
        url: LifeLog1Image,
        alt: "screenshot",
      },
      {
        url: LifeLog2Image,
        alt: "screenshot",
      },
      {
        url: LifeLog3Image,
        alt: "screenshot",
      },
      {
        url: LifeLog4Image,
        alt: "screenshot",
      },
      {
        url: LifeLog5Image,
        alt: "screenshot",
      },
      {
        url: LifeLog6Image,
        alt: "screenshot",
      },
      {
        url: LifeLog7Image,
        alt: "screenshot",
      },
      {
        url: LifeLog8Image,
        alt: "screenshot",
      },
      {
        url: LifeLog9Image,
        alt: "screenshot",
      },
      {
        url: LifeLog10Image,
        alt: "screenshot",
      },
      {
        url: LifeLog11Image,
        alt: "screenshot",
      },
    ],
    previews: [
      {
        url: LifeLog1ImagePreview,
      },
      {
        url: LifeLog2ImagePreview,
      },
      {
        url: LifeLog3ImagePreview,
      },
      {
        url: LifeLog4ImagePreview,
      },
      {
        url: LifeLog5ImagePreview,
      },
      {
        url: LifeLog6ImagePreview,
      },
      {
        url: LifeLog7ImagePreview,
      },
      {
        url: LifeLog8ImagePreview,
      },
      {
        url: LifeLog9ImagePreview,
      },
      {
        url: LifeLog10ImagePreview,
      },
      {
        url: LifeLog11ImagePreview,
      },
    ],
    links: [
      {
        url: "https://github.com/pgm-jeroendewelde/lifelog-coil-project",
        alt: "Github Repository",
        type: "github",
      },
      {
        url: "https://www.youtube.com/watch?v=4jdkNQ0lFPE",
        alt: "Youtube Screencast",
        type: "youtube",
      },
    ],
    date: {
      month: "Dec",
      year: 2021,
    },
    team: [
      {
        name: "Jan Deschacht",
        image: JanDeschachtPicture,
        link: {
          url: "https://pgm-jandeschacht.github.io/portfolio/",
          title: "Visit Jan Deschacht's portfolio",
        },
      },
    ],
  },
  {
    title: "CookBook",
    description: `
    <p>In this project <b>recipes</b> & cooks could be <b>showcased</b>. </p>
    <p>Also adding a <b>back-office</b> allowed an admin to add new recipes, link them with categories, ingredients, cooks & recipe-steps.</p>
    `,
    technologies: ["PHP", "Javascript", "MySQL", "HTML", "CSS"],
    images: [
      {
        url: CookBook1Image,
        alt: "screenshot",
      },
      {
        url: CookBook2Image,
        alt: "screenshot",
      },
      {
        url: CookBook3Image,
        alt: "screenshot",
      },
      {
        url: CookBook4Image,
        alt: "screenshot",
      },
      {
        url: CookBook5Image,
        alt: "screenshot",
      },
      {
        url: CookBook6Image,
        alt: "screenshot",
      },
      {
        url: CookBook7Image,
        alt: "screenshot back-office",
      },
      {
        url: CookBook8Image,
        alt: "screenshot",
      },
      {
        url: CookBook9Image,
        alt: "screenshot back-office",
      },
    ],
    previews: [
      {
        url: CookBook1ImagePreview,
      },
      {
        url: CookBook2ImagePreview,
      },
      {
        url: CookBook3ImagePreview,
      },
      {
        url: CookBook4ImagePreview,
      },
      {
        url: CookBook5ImagePreview,
      },
      {
        url: CookBook6ImagePreview,
      },
      {
        url: CookBook7ImagePreview,
      },
      {
        url: CookBook8ImagePreview,
      },
      {
        url: CookBook9ImagePreview,
      },
    ],
    links: [
      {
        url: "https://github.com/pgm-jeroendewelde/cook-book",
        alt: "Github Repository",
        type: "github",
      },
      {
        url: "https://youtu.be/Gt-Xil5ptqs",
        alt: "Youtube Screencast",
        type: "youtube",
      },
    ],
    date: {
      month: "Nov",
      year: 2021,
    },
  },
  {
    title: "Portfolio 2021",
    description:
      "At the end of our first year, we had to create a portfolio. I also used this one to look for an intern-job at the stat of my 2nd & final year.",
    technologies: ["Nunjucks", "11ty", "Sass", "Javascript", "HTML"],
    images: [
      {
        url: Portfolio20211Image,
        alt: "screenshot",
      },
      {
        url: Portfolio20212Image,
        alt: "screenshot",
      },
      {
        url: Portfolio20213Image,
        alt: "screenshot",
      },
      {
        url: Portfolio20214Image,
        alt: "screenshot",
      },
    ],
    previews: [
      {
        url: Portfolio20211ImagePreview,
      },
      {
        url: Portfolio20212ImagePreview,
      },
      {
        url: Portfolio20213ImagePreview,
      },
      {
        url: Portfolio20214ImagePreview,
      },
    ],
    links: [
      {
        url: "https://github.com/pgm-jeroendewelde/portfolio",
        alt: "Github Repository",
        type: "github",
      },

      {
        url: "https://pgm-jeroendewelde.github.io/portfolio/",
        alt: "Live Website",
        type: "website",
      },
    ],
    date: {
      month: "Oct",
      year: 2021,
    },
  },
  {
    title: "My Desk",
    description: `
    <p>Having to create a <b>diorama</b>, I choose to make a small copy of my own desk & adding a few animations.</p>
    <p>All the elements were created by myself in <i>Blender</i>.</p>
    `,
    technologies: ["React Three Fiber", "Blender"],
    images: [
      {
        url: MyDesk1Image,
        alt: "screenshot",
      },
      {
        url: MyDesk2Image,
        alt: "screenshot",
      },
      {
        url: MyDesk3Image,
        alt: "screenshot",
      },
      {
        url: MyDesk4Image,
        alt: "screenshot",
      },
    ],
    previews: [
      {
        url: MyDesk1ImagePreview,
      },
      {
        url: MyDesk2ImagePreview,
      },
      {
        url: MyDesk3ImagePreview,
      },
      {
        url: MyDesk4ImagePreview,
      },
    ],
    links: [
      {
        url: "https://github.com/pgm-jeroendewelde/my-desk",
        alt: "Github Repository",
        type: "github",
      },

      {
        url: "https://pgmgent-2122-im.github.io/diorama-pgm-jeroendewelde/",
        alt: "Live Demo",
        type: "website",
      },
    ],
    date: {
      month: "Nov",
      year: 2021,
    },
  },
  //   {
  //     title: "Movie Application",
  //     description: `
  //     <p>As our <i>first React application</i>, this movie application connects with an external MovieDb, fetching all the movies & storing optional (user)-data in <b>Firebase</b>.</p>
  //     `,
  //     technologies: ["Firebase", "React", "React Router"],
  //     images: [
  //       {
  //         url: TestImage1,
  //         alt: "",
  //       },
  //     ],
  //     links: [],
  //     date: {
  //       month: "June",
  //       year: 2021,
  //     },
  //   },
  {
    title: "Silly Walker App/Website",
    description: `
    <p><b>This mock-walking-application</b> was created in Adobe Xd, trying to think about <i>how a mobile app should look & feel like</i>.</p>
    <p>This mock-app had to be promoted on a <b>landing-page</b> created with 11ty.</p>
    `,
    technologies: ["Nunjucks", "11ty", "Sass", "Adobe Xd"],
    images: [
      {
        url: SillyWalkerPrototype1Image,
        alt: "screenshot Adobe Xd",
      },
      {
        url: SillyWalkerPrototype2Image,
        alt: "screenshot Adobe Xd",
      },
      {
        url: SillyWalkerPrototype3Image,
        alt: "screenshot Adobe Xd",
      },
      {
        url: SillyWalkerLandingPage1Image,
        alt: "screenshot Landing Page",
      },
      {
        url: SillyWalkerLandingPage2Image,
        alt: "screenshot Landing Page",
      },
      {
        url: SillyWalkerLandingPage3Image,
        alt: "screenshot Landing Page",
      },
      {
        url: SillyWalkerLandingPage4Image,
        alt: "screenshot Landing Page",
      },
      {
        url: SillyWalkerLandingPage5Image,
        alt: "screenshot Landing Page",
      },
      {
        url: SillyWalkerLandingPage6Image,
        alt: "screenshot Landing Page",
      },
      {
        url: SillyWalkerLandingPage7Image,
        alt: "screenshot Landing Page",
      },
      {
        url: SillyWalkerLandingPage8Image,
        alt: "screenshot Landing Page",
      },
      {
        url: SillyWalkerLandingPage9Image,
        alt: "screenshot Landing Page",
      },
    ],
    previews: [
      {
        url: SillyWalkerPrototype1ImagePreview,
      },
      {
        url: SillyWalkerPrototype2ImagePreview,
      },
      {
        url: SillyWalkerPrototype3ImagePreview,
      },
      {
        url: SillyWalkerLandingPage1ImagePreview,
      },
      {
        url: SillyWalkerLandingPage2ImagePreview,
      },
      {
        url: SillyWalkerLandingPage3ImagePreview,
      },
      {
        url: SillyWalkerLandingPage4ImagePreview,
      },
      {
        url: SillyWalkerLandingPage5ImagePreview,
      },
      {
        url: SillyWalkerLandingPage6ImagePreview,
      },
      {
        url: SillyWalkerLandingPage7ImagePreview,
      },
      {
        url: SillyWalkerLandingPage8ImagePreview,
      },
      {
        url: SillyWalkerLandingPage9ImagePreview,
      },
    ],
    links: [
      {
        url: "https://youtu.be/C1mo6WT6syk",
        alt: "Youtube Prototype",
        type: "youtube",
      },
    ],
    date: {
      month: "Mar",
      year: 2021,
    },
  },
  {
    title: "Aisukurimu",
    description: `
    <p>After a couple of weeks experimenting in Adobe Xd, I created this <b>Webshop-design</b> to a-typically <i>sell Ice-cream</i>.</p>
    `,
    technologies: ["Adobe Xd"],
    images: [
      {
        url: Aisukurimu1Image,
        alt: "screenshot Adobe Xd",
      },
      {
        url: Aisukurimu2Image,
        alt: "screenshot Adobe Xd",
      },
      {
        url: Aisukurimu3Image,
        alt: "screenshot Adobe Xd",
      },
      {
        url: Aisukurimu4Image,
        alt: "screenshot Adobe Xd",
      },
    ],
    previews: [
      {
        url: Aisukurimu1ImagePreview,
      },
      {
        url: Aisukurimu2ImagePreview,
      },
      {
        url: Aisukurimu3ImagePreview,
      },
      {
        url: Aisukurimu4ImagePreview,
      },
    ],

    date: {
      month: "Jan",
      year: 2021,
    },
  },
  {
    title: "Amor",
    description: `
    <p>This “Tinder”-clone made use of an Express back-end & called the CRUD from <b>vanilla JS</b>.</p>
    <p>A user could match with other users & send messages.</p>
    `,
    technologies: ["Javascript", "ExpressJS", "HTML", "CSS"],
    images: [
      {
        url: Amor1Image,
        alt: "screenshot",
      },
      {
        url: Amor2Image,
        alt: "screenshot",
      },
    ],
    previews: [
      {
        url: Amor1ImagePreview,
      },
      {
        url: Amor2ImagePreview,
      },
    ],
    links: [
      {
        url: "https://github.com/pgm-jeroendewelde/amor",
        alt: "Github Repository",
        type: "github",
      },
      {
        url: "https://youtu.be/s1qZVX2QNYM",
        alt: "Youtube Screencast",
        type: "youtube",
      },
    ],
    date: {
      month: "Jan",
      year: 2021,
    },
  },
  {
    title: "Dots",
    description: `<p>Making use of <i>Expo</i>, this <b>React-Native</b> application allows user to create channels & send messages to other users or inside these channels.</p><p>Note: Due to .env-variable issues, this repo is set to private. Screenshots/Screencast will be added in the future</p>`,
    technologies: [
      "React Native",
      "Expo",
      "Styled Components",
      "Typescript",
      "React Query",
      "Firebase",
      "Formik",
      "Yup",
    ],
    images: [
      {
        url: Dots1Image,
        alt: "Splash Screen",
      },
    ],
    previews: [
      {
        url: Dots1ImagePreview,
      },
    ],

    date: {
      month: "AUG",
      year: 2022,
    },
  },
  // {
  //   title: "Empty",
  //   description: "",
  //   technologies: ["", "", ""],
  //   images: [
  //     {
  //       url: TestImage1,
  //       alt: "",
  //     },
  //   ],
  //   links: [
  //     {
  //       url: "#",
  //       alt: "Github Repository",
  //       type: "github",
  //     },
  //     {
  //       url: "#",
  //       alt: "Youtube Screencast",
  //       type: "youtube",
  //     },
  //     {
  //       url: "#",
  //       alt: "Live Website",
  //       type: "website",
  //     },
  //   ],
  //   date: {
  //     month: "June",
  //     year: 2021,
  //   },
  // },
];
export default Projects;
