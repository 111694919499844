import { FiGithub, FiLinkedin, FiPhone, FiSend } from "react-icons/fi";
import styled from "styled-components";
import ProfilePicture from "../../assets/images/people/jeroen_dewelde.png";
import { colors, fonts } from "../../constants/constants";
import CtaIcon from "../buttons/cta-icon/cta-icon";
import Cta from "../buttons/cta/cta";

const Container = styled.div`
  max-width: 70rem;
  min-height: 100vh;
  height: 100%;

  position: relative;

  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "image-area"
    "title-area"
    "content-area";

  margin: 0 auto;
  margin-bottom: 2rem;

  padding-top: 5rem;

  @media (min-width: 48rem) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "image-area title-area"
      "content-area content-area";
  }

  @media (min-width: 64rem) {
    align-content: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "image-area title-area"
      "image-area content-area";
  }
`;

const HeroImage = styled.div`
  grid-area: image-area;

  max-height: 20rem;

  @media (min-width: 48rem) {
    height: 100%;
  }
  @media (min-width: 64rem) {
    height: auto;
    max-height: unset;
    align-self: center;
  }
`;

const ImgContainer = styled.div`
  height: 100%;
  max-width: 20rem;
  margin: 0 auto;
  overflow: hidden;

  animation: 10s ease-in-out infinite alternate borderRadiusHero;
  background-color: ${colors.primary};
  border: solid 2px ${colors.primaryLight};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    object-position: center top;
  }

  @media (min-width: 48rem) {
    max-width: unset;
    margin: 0 2rem;
  }

  @media (min-width: 64rem) {
    max-width: unset;
    max-height: 40rem;
  }
`;

const HeroTitle = styled.div`
  grid-area: title-area;

  @media (min-width: 48rem) {
    align-self: center;
    justify-self: start;
  }

  h1 {
    font-size: 2.5rem;
    font-family: ${fonts.secondary.bold};
    color: ${colors.primary};
    font-weight: 900;
    text-align: center;

    span {
      color: black;
      padding-left: 0.5rem;
      font-family: ${fonts.secondary.light};
    }
  }

  h3 {
    font-weight: 500;
    color: #000;
    text-align: center;
    font-size: 1.75rem;
    font-family: ${fonts.primary.regular};
    color: ${colors.grey};
  }
`;

const HeroContent = styled.div`
  grid-area: content-area;

  max-width: 40rem;

  position: relative;

  justify-self: center;

  display: flex;
  flex-direction: column;

  margin: 0 2rem;

  @media (min-width: 48rem) {
    align-self: center;
  }
  @media (min-width: 64rem) {
    margin-left: 0;
  }
`;

const IntroText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  color: ${colors.grey};

  a, a:link, a:visited {
    color: #000;
    text-decoration: none;
    &:hover, &:focus {
      color: ${colors.primary};
    }
  }
`;

const Socials = styled.div`
  width: 100%;
  max-width: 30rem;
  height: 100px;

  margin: 0 auto;
  margin-top: 1rem;

  @media (min-width: 48rem) {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 150px;
    height: 150px;
  }

  ul {
    width: 100%;
    height: 100%;

    position: relative;

    margin: 0;
    padding-left: 0;

    list-style-type: none;

    li {
      position: absolute;

      &:nth-child(1) {
        top: 0;
        left: 0;

        @media (min-width: 48rem) {
          top: 0;
          left: 100%;
          transform: translateX(-100%);
        }
      }

      &:nth-child(2) {
        top: 100%;
        left: 50%;
        transform: translate(-50%, -100%);

        @media (min-width: 48rem) {
          top: 50%;
          left: 50%;
          transform: translate(0, 0);
        }
      }

      &:nth-child(3) {
        top: 0;
        left: 100%;
        transform: translateX(-100%);

        @media (min-width: 48rem) {
          top: 100%;
          left: 0;
          transform: translate(0, -100%);
        }
      }
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 1rem;

  @media (min-width: 48rem) {
    height: 150px;

    position: relative;

    margin-top: 2rem;
  }
`;

function Hero() {
  return (
    <Container>
      <HeroImage>
        <ImgContainer>
          <img src={ProfilePicture} />
        </ImgContainer>
      </HeroImage>
      <HeroTitle>
        <h1>
          Hello There,
          <span>I'm</span>
        </h1>
        <h3>JEROEN DEWELDE</h3>
      </HeroTitle>
      <HeroContent>
        <IntroText>
          <div>
            <p>
              Seems like you stumbled accross my portfolio. Glad to see you
              here!
            </p>
            <p>
              Since September 2022 I graduated magna cum laude with an associate
              Degree in <a
                href="https://www.arteveldehogeschool.be/opleidingen/graduaat/programmeren"
                target="_blank"
              >
                Programming from <b>Artevelde University of Applied Science</b>
              </a>
              .
            </p>

            <p>
              My Stack is Javascript/Typescript, currently looking for a{" "}
              <b>Front-End Developer job</b>, aspiring to become a
              Full-Stack Developer in the coming years.
            </p>

            <p>
              Interested in my progress &amp; projects? Be sure to look around
              or contact me.
            </p>
          </div>
        </IntroText>
        <ButtonGroup>
          <div>
            <Cta
              href="mailto:Jeroen.Dewelde@gmail.com"
              text="Send me an email"
              Icon={FiSend}
              margin={false}
            />
          </div>
          <Socials>
            <ul>
              <CtaIcon
                href="https://github.com/pgm-jeroendewelde"
                Icon={FiGithub}
                title="Visit my Github Profile"
                target="_blank"
              />
              <CtaIcon
                href="tel:+32476267924"
                Icon={FiPhone}
                title="Give me a call"
              />
              <CtaIcon
                href="https://www.linkedin.com/in/jeroen-dewelde-162533134/"
                target="_blank"
                Icon={FiLinkedin}
                title="Visit my LinkedIn Profile"
              />
            </ul>
          </Socials>
        </ButtonGroup>
      </HeroContent>
    </Container>
  );
}

export default Hero;
