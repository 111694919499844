import { IconType } from "react-icons";
import styled from "styled-components";
import { colors } from "../../../constants/constants";

const Container = styled.li`
  width: 2.5rem;
  height: 2.5rem;

  animation: 10s ease-in-out infinite alternate borderRadiusSocials;

  background-color: ${colors.primary};
  color: white;

  transition: all 0.2s ease-in-out;

  a {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.2s ease-in-out;

    color: #fff;
  }
  &:hover {
    color: #fff;
    background-color: ${colors.primaryLight};

    a {
      color: ${colors.primary};
    }
  }
`;

export interface CtaIconProps {
  href: string;
  Icon: IconType;
  title?: string;
  target?: string;
}

function CtaIcon({ href, Icon, title, target }: CtaIconProps) {
  return (
    <Container>
      <a
        href={href ? href : ""}
        title={title ? title : ""}
        target={target ? target : ""}
      >
        <Icon fontSize="1.5rem" />
      </a>
    </Container>
  );
}

export default CtaIcon;
