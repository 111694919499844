import { motion } from "framer-motion";
import { useState } from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import { colors, fonts } from "../../constants/constants";
import useHideOverflow from "../../hooks/useHideOverflow";

const Background = styled.div.attrs(
  (props: { hide: boolean; fade: boolean }) => props
)`
width: 100vw;
height: 100vh;

position: fixed;
z-index: 300;
top: 0;
left: 0;
right: 0;
bottom: 0;

transition: opacity 0.5s ease-in-out .2s;

background-color: ${colors.primary};
// background-color: transparent;

opacity: ${(props) => (props.fade ? 0 : 1)};
display: ${(props) => (props.hide ? "none" : "block")};

}
`;

const InnerWrapper = styled.div`
  max-width: 66rem;
  height: 100%;

  position: relative;

  margin: 1rem 2rem;

  font-family: ${fonts.secondary.regular};

  @media (min-width: 70rem) {
    margin: 1rem auto;
  }
`;

const Wrapper = styled.div.attrs((props: { move: boolean }) => props)`
  position: absolute;
  top: ${(props) => (props.move ? "0" : "50%")};
  left: ${(props) => (props.move ? "0" : "50%")};

  display: flex;
  align-items: center;

  transform: ${(props) =>
    props.move ? "translate(0, 0)" : "translate(-50%, -50%)"};

  transition: all 0.8s ease-in-out 0.5s, font-size 0.4s ease-in-out 0.6s;

  font-size: ${(props) => (props.move ? "1rem" : "2rem")};
  color: #fff;

  @media (min-width: 48rem) {
    font-size: ${(props) => (props.move ? "1rem" : "2.5rem")};
  }
`;

const LeftTag = styled.div.attrs(
  (props: { hide: boolean; remove: boolean }) => props
)`
  max-width: 5rem;

  align-self: center;

  display: ${(props) => (props.remove ? "none" : "block")};

  transition: visibility 0.4s ease-in-out;

  visibility: ${(props) => (props.hide ? "hidden" : "visible")};

  font-family: ${fonts.secondary.bold};
  font-size: 4rem;
  text-align: right;
  color: #fff;
  padding-right: 1rem;

  @media (min-width: 48rem) {
    font-size: 3rem;
  }
`;

const RightTag = styled.div.attrs(
  (props: { hide: boolean; remove: boolean }) => props
)`
  max-width: 5rem;

  align-self: center;

  display: ${(props) => (props.remove ? "none" : "block")};

  transition: visibility 0.4s ease-in-out;

  visibility: ${(props) => (props.hide ? "hidden" : "visible")};

  font-family: ${fonts.secondary.bold};
  font-size: 3.5rem;
  color: #fff;

  @media (min-width: 48rem) {
    font-size: 2.5rem;
  }
`;

const Content = styled.div.attrs(
  (props: { eraseText: boolean; removeSpacing: boolean; move: boolean }) =>
    props
)`
  padding: 0 ${(props) => (props.move ? "0" : "1rem")};
  font-family: ${fonts.secondary.regular};

  .Typewriter {
    height: 100%;
    display: flex;

    @media (min-width: 64rem) {
      display: block;
    }

    &__wrapper {
      .text {
        display: inline-flex;

        &.right {
          padding-left: 0;
          transition: all 0.4s ease-in-out;

          @media (min-width: 48rem) {
            padding-left: ${(props) => (props.removeSpacing ? "0" : "4px")};
          }
        }

        .bold {
          transition: all 0.4s ease-in-out 0.4s;
          font-weight: ${(props) => (props.move ? "400" : "bold")};
        }

        .extra,
        .extra2 {
          max-width: ${(props) => (props.eraseText ? "0" : "120px")};
          display: inline-block;
          transition: all 3s ease-in-out;
          overflow: hidden;
          background-color: ${colors.primary};
          flex: 1;

          @media (min-width: 48rem) {
            max-width: ${(props) => (props.eraseText ? "0" : "140px")};
          }
        }
      }
    }
  }
`;

const SkipAnimation = styled.a`
position: absolute;
bottom: 2rem;
right: 0;

padding: .5rem 1rem;

border: solid 2px transparent;
border-radius: 5px;

color: rgba(255, 255, 255, 0.5);

transition: all 0.4s ease-in-out;

cursor: pointer;

&:hover, &:focus {
  color: rgba(255, 255, 255, 1);
  border: solid 2px white;
}
`;

function NameTag() {
  const [eraseText, setEraseText] = useState(false);
  const [hideBrackets, setHideBrackets] = useState(false);
  const [removeContent, setRemoveContent] = useState(false);
  const [moveToTop, setMoveToTop] = useState(false);
  const [fadeBackground, setFadeBackground] = useState(false);
  const [removeAll, setRemoveAll] = useState(false);

  useHideOverflow(!removeAll);
  
  const handleSkipAnimation = () => {
    setEraseText(true);
    setHideBrackets(true);
    setRemoveContent(true);
    setMoveToTop(true);
    setFadeBackground(true);
    setRemoveAll(true);
  };

  return (
    <>
      <Background fade={fadeBackground} hide={removeAll}>
        <InnerWrapper>
          <SkipAnimation href="#" onClick={handleSkipAnimation}>
            Skip animation
          </SkipAnimation>
          <Wrapper move={moveToTop}>
            <LeftTag hide={hideBrackets} remove={removeContent}>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <b>&lt;</b>
              </motion.div>
            </LeftTag>

            <Content
              eraseText={eraseText}
              removeSpacing={removeContent}
              move={moveToTop}
            >
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString(
                      `<span class="text"><span class="bold">J</span><span class="extra">eroen</span></span><span class="text right"><span class="bold">D</span><span class="extra2">ewelde</span></span>`
                    )
                    .pauseFor(700)
                    .callFunction(() => {
                      setEraseText(true);
                    })
                    .pauseFor(500)
                    .callFunction(() => {
                      setHideBrackets(true);
                    })
                    .pauseFor(400)
                    .callFunction(() => {
                      setRemoveContent(true);
                    })
                    .pauseFor(400)
                    .callFunction(() => {
                      setMoveToTop(true);
                    })
                    .pauseFor(2000)
                    .callFunction(() => {
                      setFadeBackground(true);
                    })
                    .pauseFor(1000)
                    .callFunction(() => {
                      setRemoveAll(true);
                    })
                    .start();
                }}
              />
            </Content>

            <RightTag hide={hideBrackets} remove={removeContent}>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <b>&#8725;&gt;</b>
              </motion.div>
            </RightTag>
          </Wrapper>
        </InnerWrapper>
      </Background>
    </>
  );
}

export default NameTag;
